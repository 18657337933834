import {CurrentUserContext} from '@/models/CurrentUserContext'
import {bcp47List} from '@/utils/constants'
import {
	NavItemLinkContentItem,
	AuthNavItemLinkContentItem,
} from '@/_new-code/products/flexible-web-toolkit/components/header/models'
import {Tersed} from '@/_new-code/services/kontent-ai/types'

const matchAbsoluteUrlPath = (path: string): boolean => {
	// eslint-disable-next-line prefer-regex-literals
	const pattern = new RegExp('^(?:[a-z+]+:)?//', 'i')
	// returns 'true' if the URL is external
	return pattern.test(path)
}

export const addLocalePrefix = (url: string, locale: string): string => {
	const trimmedUrl = url.trim()
	const isExternal = matchAbsoluteUrlPath(trimmedUrl)
	const keys = Object.keys(bcp47List)

	if (trimmedUrl === '') return trimmedUrl
	if (
		['mailto:', 'tel:', 'sms:', '#'].some((prefix) =>
			trimmedUrl.startsWith(prefix)
		)
	)
		return trimmedUrl

	// If the URL starts with locale or is an external URL
	if (isExternal || trimmedUrl.startsWith(`/${locale}`)) {
		return trimmedUrl
	}
	// If the URL does not start with forward slash '/' and  with the desired '/locale' but instead with different locale
	if (
		!trimmedUrl.startsWith('/') &&
		!trimmedUrl.startsWith(`/${locale}`) &&
		keys.some((key) => trimmedUrl.startsWith(`${key}`))
	) {
		return `/${trimmedUrl}`
	}
	// If the URL starts with a locale without the forward slash '/'
	if (!trimmedUrl.startsWith('/') && trimmedUrl.startsWith(`${locale}`)) {
		return `/${trimmedUrl}`
	}
	// If the URL does not starts with both forward slash '/' and  with '/locale'
	if (!trimmedUrl.startsWith('/') && !trimmedUrl.startsWith(`/${locale}`)) {
		return `/${locale}/${trimmedUrl}`
	}
	// If the URL starts with forward slash '/' and does not start with desired '/locale' but instead starts with different locale
	if (
		trimmedUrl.startsWith('/') &&
		!trimmedUrl.startsWith(`/${locale}`) &&
		keys.some(
			(key) =>
				trimmedUrl.startsWith(`/${key}/`) || trimmedUrl === `/${key}`
		)
	) {
		return `${trimmedUrl}`
	}
	return `/${locale}${trimmedUrl}`
}

const processUrl = (url: string, locale: string): string | undefined => {
	if (!url) {
		return undefined
	}
	return addLocalePrefix(url, locale)
}

export function mapLinksWithLocale(
	arr: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[],
	locale: string
): Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[] {
	return arr.map((link) => ({
		...link,
		elements: {
			...link.elements,
			url: processUrl(link.elements.url ?? '', locale) ?? '',
			sublinks: mapLinksWithLocale(link.elements.sublinks, locale),
		},
	}))
}

export const filterLinksByAppPermissionsAndRoles = (
	arr: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[],
	authState: CurrentUserContext
): Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[] =>
	arr
		.filter((item) => {
			const hasPermission = authState.checkAppPermission(item)
			const hasRole = authState.checkUserRole(item)
			return hasPermission || hasRole
		})
		.map((item) => ({
			...item,
			elements: {
				...item.elements,
				sublinks:
					item.elements.sublinks.length > 0
						? filterLinksByAppPermissionsAndRoles(
								item.elements.sublinks,
								authState
							)
						: [],
			},
		}))

export const filterBlocksByUserRole = (
	blocks: Tersed[],
	authState: CurrentUserContext
) => {
	return blocks.filter((block) => authState.checkUserRoleForBlock(block))
}

export const getContainerClass = (
	container: string,
	defaultContainer = 'container-wide'
): string => {
	switch (container) {
		case 'page': {
			return 'w-full'
		}
		case 'container': {
			return 'container'
		}
		case 'wide': {
			return 'container-wide'
		}
		case 'narrow': {
			return 'container-narrow'
		}
		default: {
			return defaultContainer
		}
	}
}

interface CustomBrand {
	background: string
	border: string
}
interface CustomBrandObject {
	[k: string]: CustomBrand
}

// Verbose object, creating these dynamically would be purged by Tailwind
/* Brands which have colors that are not a part of the Component Library's original set */
export const customBrands: CustomBrandObject = {
	elura: {
		background: 'bg-elura',
		border: 'border-elura',
	},
	entyce: {
		background: 'bg-entyce',
		border: 'border-entyce',
	},
	comfortis: {
		background: 'bg-comfortis',
		border: 'border-comfortis',
	},
	advocate: {
		background: 'bg-advocate',
		border: 'border-advocate',
	},
	experior_green: {
		background: 'bg-experior_green',
		border: 'border-experior_green',
	},
	zenrelia_primary: {
		background: 'bg-zenrelia_primary',
		border: 'border-zenrelia_primary',
	},
	zenrelia_secondary: {
		background: 'bg-zenrelia_secondary',
		border: 'border-zenrelia_secondary',
	},
	zenrelia_contrast: {
		background: 'bg-zenrelia_contrast',
		border: 'border-zenrelia_contrast',
	},
	zenrelia_accent: {
		background: 'bg-zenrelia_accent',
		border: 'border-zenrelia_accent',
	},
	zenrelia_sku1: {
		background: 'bg-zenrelia_sku1',
		border: 'border-zenrelia_sku1',
	},
	zenrelia_sku2: {
		background: 'bg-zenrelia_sku2',
		border: 'border-zenrelia_sku2',
	},
	zenrelia_sku3: {
		background: 'bg-zenrelia_sku3',
		border: 'border-zenrelia_sku3',
	},
	zenrelia_sku4: {
		background: 'bg-zenrelia_sku4',
		border: 'border-zenrelia_sku4',
	},
	credelio_quattro_guard_dog: {
		background: 'bg-credelio_quattro_guard_dog',
		border: 'border-credelio_quattro_guard_dog',
	},
	credelio_quattro_hunter_green: {
		background: 'bg-credelio_quattro_hunter_green',
		border: 'border-credelio_quattro_hunter_green',
	},
	credelio_quattro_grass_green: {
		background: 'bg-credelio_quattro_grass_green',
		border: 'border-credelio_quattro_grass_green',
	},
	credelio_quattro_tennis_ball_green: {
		background: 'bg-credelio_quattro_tennis_ball_green',
		border: 'border-credelio_quattro_tennis_ball_green',
	},
	credelio_quattro_park_green: {
		background: 'bg-credelio_quattro_park_green',
		border: 'border-credelio_quattro_park_green',
	},
	credelio_quattro_milk_bone: {
		background: 'bg-credelio_quattro_milk_bone',
		border: 'border-credelio_quattro_milk_bone',
	},

	credelio_quattro_golden: {
		background: 'bg-credelio_quattro_golden',
		border: 'border-credelio_quattro_golden',
	},
	credelio_quattro_frisbee: {
		background: 'bg-credelio_quattro_frisbee',
		border: 'border-credelio_quattro_frisbee',
	},
	credelio_quattro_sunny_day: {
		background: 'bg-credelio_quattro_sunny_day',
		border: 'border-credelio_quattro_sunny_day',
	},
	credelio_quattro_sky_blue: {
		background: 'bg-credelio_quattro_sky_blue',
		border: 'border-credelio_quattro_sky_blue',
	},
}

export const customBrandChoices: string[] = Object.keys(customBrands)
